<template>
  <router-view></router-view>
</template>

<script>
// import { useRecaptchaProvider } from 'vue-recaptcha/head'

// useRecaptchaProvider()

export default {
  name: 'App',
  components: {
  }
}
</script>
