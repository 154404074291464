export default [
  {
    path: "/reference",
    component: () => import("@/layouts/vertical.vue"),
    children: [
      {
        path: "platform",
        name: "reference-platform",
        meta: {
          title: "Platform", authRequired: true,
        },
        component: () => import("../views/reference/platform/index"),
      },
      {
        path: "platform/edit/:id(\\d+)+",
        name: "reference-platform-edit",
        meta: {
          title: "Edit Platform", authRequired: true,
        },
        component: () => import("../views/reference/platform/edit"),
      },
      {
        path: "platform/add",
        name: "reference-platform-add",
        meta: {
          title: "Add Platform", authRequired: true,
        },
        component: () => import("../views/reference/platform/new"),
      },
      {
        path: "legal",
        name: "reference-legal",
        meta: {
          title: "Legal Basis", authRequired: true,
        },
        component: () => import("../views/reference/legal/index"),
      },
      {
        path: "legal/edit/:id(\\d+)+",
        name: "reference-legal-edit",
        meta: {
          title: "Edit Legal Basis", authRequired: true,
        },
        component: () => import("../views/reference/legal/edit"),
      },
      {
        path: "legal/add",
        name: "reference-legal-add",
        meta: {
          title: "Add Legal Basis", authRequired: true,
        },
        component: () => import("../views/reference/legal/new"),
      },
      {
        path: "/instansi",
        name: "reference-instansi",
        meta: {
          title: "Instansi", authRequired: true,
        },
        component: () => import("../views/reference/instansi"),
      },
      {
        path: "/source",
        name: "reference-source",
        meta: {
          title: "Source", authRequired: true,
        },
        component: () => import("../views/reference/source"),
      },
      {
        path: "/compliance",
        name: "reference-compliance",
        meta: {
          title: "Index Kepatuhan", authRequired: true,
        },
        component: () => import("../views/reference/compliance"),
      },
      {
        path: "/content",
        name: "reference-content",
        meta: {
          title: "Index Konten", authRequired: true,
        },
        component: () => import("../views/reference/content"),
      },
      {
        path: "/compliance-order",
        name: "reference-compliance-order",
        meta: {
          title: "Index Teguran", authRequired: true,
        },
        component: () => import("../views/reference/compliance-order"),
      },
      {
        path: "/ugc",
        name: "reference-ugc",
        meta: {
          title: "Index UGC", authRequired: true,
        },
        component: () => import("../views/reference/ugc"),
      },
      {
        path: "/scale",
        name: "reference-scale",
        meta: {
          title: "Index Skala Usaha", authRequired: true,
        },
        component: () => import("../views/reference/scale"),
      },
      {
        path: "/virality",
        name: "reference-virality",
        meta: {
          title: "Index Viralitas", authRequired: true,
        },
        component: () => import("../views/reference/virality"),
      },
      {
        path: "/user",
        name: "reference-user",
        meta: {
          title: "User", authRequired: true,
        },
        component: () => import("../views/reference/user/index"),
      },
      {
        path: "/appeal-reason",
        name: "reference-appeal-reason",
        meta: {
          title: "Appeal Reason", authRequired: true,
        },
        component: () => import("../views/reference/appeal-reason"),
      },
      // {
      //   path: "/analytics",
      //   name: "reference-analytics",
      //   meta: {
      //     title: "Analytics", authRequired: true,
      //   },
      //   component: () => import("../views/dashboard/analytics/index"),
      // },
      // {
      //   path: "/job",
      //   name: "reference-job",
      //   meta: {
      //     title: "Job Dashboard", authRequired: true,
      //   },
      //   component: () => import("../views/dashboard/job/index"),
      // },
      // {
      //   path: "/list",
      //   name: "reference-list",
      //   meta: { title: "references List", authRequired: true },
      //   component: () => import("../views/references/index"),
      // },
      // {
      //   path: "/new-batch",
      //   name: "reference-new-batch",
      //   meta: { title: "New Batch", authRequired: true },
      //   component: () => import("../views/references/new"),
      // },
      // {
      //   path: "/edit/:id(\\d+)+",
      //   name: "reference-edit-batch",
      //   meta: { title: "Edit Batch", authRequired: true },
      //   component: () => import("../views/references/edit"),
      // },
      // {
      //   path: "/show/:id(\\d+)+",
      //   name: "reference-show-batch",
      //   meta: { title: "View Batch", authRequired: true },
      //   component: () => import("../views/references/show"),
      // },
      // {
      //   path: "/verified",
      //   name: "reference-verified",
      //   meta: { title: "Verified List", authRequired: true },
      //   component: () => import("../views/verification/index"),
      // },
      // {
      //   path: "/view/:id(\\d+)+",
      //   name: "view-batch",
      //   meta: { title: "View Batch", authRequired: true },
      //   component: () => import("../views/verification/view"),
      // },
      // {
      //   path: "/approve",
      //   name: "reference-approve",
      //   meta: { title: "Approval List", authRequired: true },
      //   component: () => import("../views/approval/index"),
      // },
    ],
  },
];  